<route>
{
  "meta": {
    "auth": "orderManage"

  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">用户ID</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入用户ID"
            v-model="searchValue.memberId"
            @keyup.enter.native="getList"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">用户昵称</span>
          <el-input
            class="searchInput"
            placeholder="请输入用户昵称"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.memberName"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">订单</span>
          <el-input
            @keyup.enter.native="getList"
            class="searchInput"
            placeholder="请输入订单"
            v-model.trim="searchValue.id"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">订单状态</span>

          <el-select placeholder="请选择订单状态" v-model="searchValue.expressOrderStatus">
            <el-option
              v-for="(item, index) in stateOptions"
              :label="item.label"
              :key="index"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <span class="searchText">商家名称</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            placeholder="请输入商户名称"
            v-model.trim="searchValue.businessName"
            clearable
          >
          </el-input>
        </el-col>
       <el-col :span="8">
          <span class="searchText">商品ID</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            placeholder="请输入商品ID"
            v-model.trim="searchValue.productId"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">手机号</span>
          <el-input
            class="searchInput"
            placeholder="请输入手机号"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.memberMobile"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">选项ID</span>
          <el-input
            class="searchInput"
            placeholder="请输入选项ID"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.optionsId"
            clearable
          >
          </el-input>
        </el-col>
        <el-form inline>
          <bm-areaSelect v-model="searchValue.districtId"></bm-areaSelect>
        </el-form>
      </el-row>
      <el-row :gutter="20" class="query">
   
            <el-col :span="8">
          <span class="searchText">商户id</span>
          <el-input
            class="searchInput"
            placeholder="请输入商户id"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.businessId"
            clearable
          >
          </el-input>
          </el-col>
                   <el-col :span="8">
          <span class="searchText">款式ID</span>
          <el-input
            class="searchInput"
            placeholder="请输入款式ID"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.skuId"
            clearable
          >
          </el-input>

        </el-col>
          </el-row>
           <el-row :gutter="20" class="query">
     <el-col :span="11">
          <span class="searchText">订单创建时间</span>
          <el-date-picker
            v-model="orderTime"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
   
        <el-col :span="5">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span >订单列表</span>

        <el-button type="success" @click="downloadExcel()" style="float: right"> 导出</el-button>

        <el-button type="success" @click="batchDeliver" style="float: right; margin-right: 10px"> 批量发货</el-button>
      </div>
      <el-table :data="list" v-loading="loading" :row-key="getRowKeys" :expand-row-keys="expands">
        <el-table-column align="center" prop="bmOrderId" label="订单号"> </el-table-column>
        <el-table-column align="center" label="商品信息">
          <template slot-scope="{row}" >
            <div class="product-info" v-for="item in row.skuList" :key="item.id + 'product'">
              <img class="avatar" :src="item.productPic | thumbMedium" />

              <span class="name"> {{ item.productName }}</span>
            </div>
          </template>
        </el-table-column>

            <el-table-column align="center" label="款式信息">
          <template slot-scope="{row}" >
            <div class="product-info" v-for="item in row.skuList" :key="item.id + 'sku' ">
              <img class="avatar" :src="item.skuPic | thumbMedium" />
              <span class="name"> {{ item.skuName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="businessName" label="商家名称"> </el-table-column>
        <el-table-column align="center" prop="memberName" label="用户昵称">
          <template slot-scope="{ row }">
            
            昵称：{{row.memberName}}</br>
            id: {{row.memberId}}
            
          </template>
        </el-table-column>



        <el-table-column align="center" prop="skuTotalNum" label="购买数量">
          <template slot-scope="{ row }">
            <div v-for="item in row.skuList" :key="item.skuNum">{{ item.skuNum }}</div>
          </template></el-table-column
        >

        <el-table-column align="center" prop="skuPayMoney" label="地区">
          <template slot-scope="{ row }">
            <div v-for="item in row.skuList" :key="item.id + 'areaId'">{{ item.areaId | formatArea }}</div>
          </template>
        </el-table-column>

           <el-table-column align="center" prop="skuPayMoney" label="支付金额">
          <template slot-scope="{ row }">
            <div v-for="item in row.skuList" :key="item.id + 'areaId'">{{ item.actualPayMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="订单状态">
          <template slot-scope="scope">
            {{ scope.row.expressOrderStatus | expressStatus }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="orderTime" label="订单创建时间">
          <template slot-scope="scope">
            {{ scope.row.orderTime | formatTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="check(scope.row, 'check')" type="text" size="small"> 查看 </el-button>

            <el-button @click.stop="singleRefund(scope.row)"  v-if="scope.row.expressOrderStatus == 1  || scope.row.expressOrderStatus == 2  || scope.row.expressOrderStatus == 3  || scope.row.expressOrderStatus == 4   " type="text" size="small"> 退款 </el-button>
            <!-- <el-button @click.stop="deliver(scope.row)" type="text" size="small"> 发货 </el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" title="导出订单" :visible.sync="download.show" append-to-body width="30%">
      <span style="display: inline-block; width: 60px"> 时间</span>
      <el-date-picker
        value-format="timestamp"
        v-model="download.time"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>

      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 状态</span>
        <el-select placeholder="请选择订单状态" v-model="download.orderStatus">
          <el-option
            v-for="(item, index) in stateOptions"
            :label="item.label"
            :key="index"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 商品ID</span>
        <el-input placeholder="输入商品ID" style="width: 300px" v-model="download.productId"> </el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownload">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="showSingRefundDialog"
      append-to-body
      center
      width="30%"
    >
      <div style="text-align: center">是否确定将此订单退款</div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showSingRefundDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmSingleRefund">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 单个发货 -->
    <el-dialog
      :close-on-click-modal="false"
      title="发货"
      :visible.sync="showDeliverDialog"
      append-to-body
      center
      width="30%"
    >
      <div>订单号:{{ rowId }}</div>

      <div>
        <span style="margin-right: 24px">物流公司</span>

        <el-select placeholder="物流公司" v-model="expressOrderStatus">
          <el-option label="京东" value="1">京东</el-option>
          <el-option label="顺丰" value="3">顺丰</el-option>
        </el-select>
      </div>

      <div>
        <span style="margin-right: 24px">物流单号</span>
        <el-input
          class="searchInput"
          placeholder="请输入物流单号"

          clearable
        >
        </el-input>
      </div>
      <div></div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showDeliverDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmSingleDeliver">确 定</el-button>
      </span>
    </el-dialog>

 
  </div>
</template>

<script>
// 引入分页的混入
import { pagination } from '@/mixin/pagination'

export default {
  mixins: [pagination],
  data() {
    return {
      getRowKeys(row) {
        return row.id
      },
      showDeliverDialog: false,
      showSingRefundDialog: false,
      rowId: '',
      // 要展开的行，数值的元素是row的key值
      expands: [],
      expressOrderStatus: '1',
      data: [],
      stateOptions: [
        {
          value: null,
          label: '全部'
        },
        {
          value: '0',
          label: '待支付'
        },
        {
          value: '1',
          label: '待发货'
        },
        {
          value: '2',
          label: '已发货'
        },
        {
          value: '3',
          label: '已收货'
        },
        {
          value: '4',
          label: '已评价'
        },
        {
          value: '5',
          label: '已关闭'
        },
        {
          value: '6',
          label: '交易完成'
        },
        {
          value: '7',
          label: '交易取消'
        }
      ],
      download: {
        orderStatus: null,
        show: false,
        url: '',
        time: [],
        productId: ''
      },
      // 搜索状态栏的时间，因为是个数组，所以不能直接赋值给search
      orderTime: [],
      searchValue: {
        id: '',
        businessName: '',
        memberId: '',
        optionsId: '',
        skuId: '',
        productId: '',
        memberName: '',
        expressOrderStatus: '',
        orderTimeEnd: '',
        businessId: '',
        orderTimeStart: '',
        memberMobile: '',
        buyTypeList: '4' //0: 普通； 1： 霸王餐， 3：限时特卖
      },
      loading: false,
      // 表格的数据
      list: []
    }
  },
  filters: {
    expressStatus(val) {
      switch (val) {
        case 0:
          return '待支付'
          break
        case 1:
          return '待发货'
          break
        case 2:
          return '已发货'
          break
        case 3:
          return '已收货'
          break
        case 4:
          return '已评价'
          break
        case 5:
          return '已关闭'
          break
        case 6:
          return '交易完成'
          break
        case 7:
          return '交易取消'
          break
        default:
          return '没有匹配到'
          break
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.searchValue.skuId = this.$route.query.id
      this.getList()
    }
  },
  methods: {
    singleRefund(row) {
      this.rowId = row.bmOrderId
      this.showSingRefundDialog = true
    },
    confirmSingleRefund() {
      this.$http
        .post('/boom-mix-biz-service/sysAdmin/order/express/refund', { bmOrderIdList: [this.rowId] })
        .then(res => {
          this.$message.success('退款成功')
          this.showSingRefundDialog = false
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    deliver(row) {
      this.rowId = row.bmOrderId
      this.showDeliverDialog = true
    },
    batchDeliver() {
      this.$router.push({
        path: '/product/partnerProduct/expressProductList/batchDeliver'
      })
    },

    confirmSingleDeliver() {},
    // 弹框搜索订单
    handleSearch() {
      if (this.batchRefund.id === '') {
        this.$message.error('请输入商品ID')
        this.batchRefund.businessName = ''
        return
      }
      let config = { ['Bm-Area-Id']: this.searchValue.districtId }
      let obj = {
        memberId: '',
        memberName: '',
        optionsId: '',
        skuId: '',
        businessName: '',
        expressOrderStatus: this.expressOrderStatus,
        memberMobile: '',
        businessId: '',
        districtId: '',
        orderTimeEnd: this.orderTime[1],
        orderTimeStart: this.orderTime[0],
        buyTypeList: '0,1,3'
      }

      if (this.data) {
        obj.orderTimeStart = this.data[0]
        obj.orderTimeEnd = this.data[1]
      } else {
        obj.orderTimeStart = ''
        obj.orderTimeEnd = ''
      }
      let payload = {
        size: 10000,
        id: this.batchRefund.id,
        ...obj
      }
      this.$api.order
        .order('orderList', {
          params: payload,
          headers: config
        })
        .then(res => {
          this.batchRefund.list = res.list
          this.batchRefund.total = res.total
          if (this.batchRefund.list.length === 0) {
            this.$message.error(
              `没有找到${this.expressOrderStatus == 1 ? '未核销' : '已过期'}状态的订单，请检查商品ID或者状态`
            )

            this.batchRefund.businessName = ''
          } else {
            // this.batchRefund.skuPic = this.batchRefund.list[0].skuPic
            this.batchRefund.productName = this.batchRefund.list[0].productName
            this.batchRefund.businessName = this.batchRefund.list[0].businessName
          }
        })
        .catch(err => {
          this.$message.error('获取订单表格错误')
        })
    },

    // 点击重置按钮，先清空再搜索
    resetSearch() {
      this.orderTime = []
      this.searchValue = {
        id: '',
        memberId: '',
        memberName: '',
        optionsId: '',
        skuId: '',
        businessName: '',
        expressOrderStatus: '',
        businessId: '',
        productName: '',
        productId: '',
        memberMobile: '',
        districtId: '',
        orderTimeEnd: this.$store.getters['global/todayInt'],
        orderTimeStart: this.$store.getters['global/day7Int'],
        buyTypeList: '0,1,3'
      }
      this.getList(false, true)
    },
    // 搜索
    getList(val, reset) {
      this.loading = true
      let config =
        reset === true
          ? {}
          : {
              ['Bm-Area-Id']: this.searchValue.districtId
            }

      if (this.orderTime) {
        this.searchValue.orderTimeStart = this.orderTime[0]
        this.searchValue.orderTimeEnd = this.orderTime[1]
      } else {
        this.searchValue.orderTimeStart = ''
        this.searchValue.orderTimeEnd = ''
      }

      this.searchValue.size = this.pageSize

      // 判断是否是分页还是普通搜索
      this.searchValue.page = val === true ? this.currentPage : 1

      let { districtId, ...payload } = this.searchValue
      this.loading = true

      this.$http
        .get('/boom-center-search-service/sysAdmin/expressOrder/page', {
          params: payload,
          headers: config
        })
        .then(res => {
          this.list = res.list
          this.total = res.total
        })
        .catch(err => {
          this.$message.error('获取订单表格错误')
        })
        .finally(() => {
          this.loading = false
        })
    },

    // 查看订单
    check(row) {
      this.$router.push({
        path: '/order/express/orderParticulars',
        query: {
          id: row.bmOrderId
        }
      })
    },
    //==========================================================================下载Excel表格
    confirmDownload() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .get(this.download.url, {
          params: {
            startTime: this.download.time ? this.download.time[0] : '',
            endTime: this.download.time ? this.download.time[1] : '',
            orderStatus: this.download.orderStatus,
            productId: this.download.productId
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    downloadExcel() {
      this.download = {
        orderStatus: null,
        show: true,
        url: '/boom-center-statistics-service/sysAdmin/exportExl/expressOrder',
        time: [],
        productId: ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.product-info {
  display: flex;
  align-items: center;
  .name {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    vertical-align: middle;
    object-fit: cover;
    border-radius: 5px;
  }
}

.query {
  margin-top: 20px;
}
.checkCode {
  display: flex;
  justify-content: center;
  .item {
    display: flex;
    align-items: center;
    .desc {
      margin: 0 20px;
    }
    .item {
      margin: 5px;
    }
  }
}
.vendor {
  .item {
    display: flex;
    align-items: center;
    margin: 30px;
    .name {
      display: inline-block;
      width: 100px;
    }
    img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
    }
  }
}
</style>
